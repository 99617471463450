<template>
  <div class="home" v-if="true">
    <div class="home__img">
      <img :src="img" alt="" />
    </div>
    <div class="home__text">
      <span>Привет!</span>
      <h2 class="home__title">
        Я <span>{{ text }}</span>
      </h2>
      <p>
        Я Фрилансер и Веб разработчик. Я стремлюсь создавать захватывающие и
        красивые веб сайты и приложении , тщательно продуманный код и удобста
        для пользователю при использований моих веб приложении.
      </p>

      <div class="home__links">
        <router-link class="btn-hover_gray" to="/about">
          <img src="../assets/images/profile-icon.svg" alt="" />
          <span>Больше обо мне</span>
        </router-link>
        <router-link class="btn-hover_blue" to="/portfolio">
          <img src="../assets/images/bag.svg" alt="" />
          <span>Портфолио</span></router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      text: "",
      textArr: ["Алладдин Курбанбаев", "Фрилансер", "Веб разработчик"],
      i: 0,
      j: 0,
    };
  },
  computed: {
    ...mapState(["img"]),
  },
  methods: {
    textAdd() {
      let interval = setInterval(() => {
        if (this.i == this.textArr.length - 1) {
          this.i = 0;
        }
        this.text += this.textArr[this.i][this.j];
        this.j++;
        if (this.text.length == this.textArr[this.i].length) {
          clearInterval(interval);
          this.textRemove();
        }
      }, 120);
    },
    textRemove() {
      setTimeout(() => {
        let interval = setInterval(() => {
          let arr = this.text.split("").slice(0, this.text.length - 1);
          this.text = arr.join("");
          if (this.text.length == 0) {
            clearInterval(interval);
            this.textAdd();
            this.i++;
            this.j = 0;
          }
        }, 70);
      }, 700);
    },
  },

  created() {
    this.textAdd();
  },
};
</script>
<style lang="scss"></style>
