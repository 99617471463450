import { createStore } from "vuex";
import axios from "axios";
export default createStore({
  state: {
    repos: !localStorage.repos ? null : localStorage.repos,
    img: require("@/assets/images/profile-pic.jpg"),
  },
  getters: {},
  mutations: {
    getRepos(state, payload) {
      state.repos = payload;
    },
  },
  actions: {
    async getRepos(context) {
      if (!localStorage.repos) {
        let response = await axios.get(
          "https://api.github.com/users/AlladdinK/repos"
        );
        localStorage.repos = JSON.stringify(response.data);
        context.commit("getRepos", response.data);
      } else {
        context.commit("getRepos", JSON.parse(localStorage.repos));
      }
    },
    async getRezume(context) {
      let resp = await axios.get(
        "https://api.github.com/users/PurpleLightning22/repos"
      );
      context.commit("getRepos", resp.data);
    },
  },
});
